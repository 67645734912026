import { Layout, Head } from '@/components'
import { Hero, ReviewsCTA, Mission, Contribute } from '@/components/Home'
import { GetServerSidePropsContext } from 'next'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  return {
    props: {
      query: context.query,
    },
  }
}

function HomePage({ query }) {
  return (
    <Layout className="relative">
      <Head title="" description="Welcome to Good Neighbour" />
      <div className="">
        <Hero latitude={query.latitude} longitude={query.longitude} city={query.city} />
        <ReviewsCTA />
        {/* <Platform /> */}
        <Mission />
        <Contribute />
      </div>
    </Layout>
  )
}

export default HomePage
