import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

import { ButtonLink, ErrorBoundary } from '@/components'
import { AnimatePresence, motion } from 'framer-motion'

import { ALL_LISTINGS_QUERY } from '@/queries'
import { useQuery } from '@apollo/client'

const DynamicMap = dynamic(() => import('../Map'), {
  ssr: false,
})

export const Hero = ({ latitude, longitude }: { latitude: number; longitude: number; city: string | null }) => {
  const { data } = useQuery(ALL_LISTINGS_QUERY, {
    variables: {
      first: 50,
      filters: {
        listingType: null,
        laundry: false,
        bedrooms: 0,
        bathrooms: 0,
        airConditioning: false,
        accessibility: false,
        parkingAvailable: false,
        petsWelcome: false,
        rentHigh: 10000,
        rentLow: 0,
        utilitiesIncluded: false,
        dishwasher: false,
      },
      longitude: Number(longitude),
      latitude: Number(latitude),
    },
  })

  const [mapReady, setMapReady] = useState(false)

  useEffect(() => {
    if (window) {
      setMapReady(true)
    }
  }, [])

  return (
    <div className="pt-0 sm:pt-12 relative pb-0 lg:pt-20 xl:pt-32 lg:pb-72 overflow-hidden  from-brand-500/10 dark:from-brand-500/10 via-white/10 dark:via-zinc-900/0 to-zinc-900/0 bg-opacity-5">
      <div className="w-full relative z-10 mx-auto px-6 lg:px-8 sm:max-w-6xl lg:max-w-7xl">
        <div>
          <div className="">
            <div className="mt-6 lg:w-3/5 pointer-events-none">
              <h1 className="text-zinc-700 dark:text-zinc-200 text-5xl font-sans font-extrabold tracking-tighter md:text-7xl xl:text-8xl text-center lg:text-left">
                Say goodbye to rental regret
              </h1>

              <p className="mx-auto lg:mx-0 max-w-xl lg:max-w-2xl mt-4 lg:mt-8 text-base sm:text-xl dark:text-zinc-300 text-zinc-600 tracking-tight font-medium text-center lg:text-left">
                Take the uncertainty out of renting by reading testimonials from tenants about their experience.
              </p>
            </div>

            <div className="mt-10 flex flex-col sm:flex-row justify-center lg:justify-start items-center gap-4 sm:gap-8">
              <ButtonLink url="/explore" internal>
                Explore reviews
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <div className="mt-6 lg:mt-0 w-full sm:mx-auto sm:max-w-3xl sm:px-6 h-[360px] lg:h-auto">
          <div className="py-6 relative lg:mt-12 lg:py-0 lg:absolute lg:inset-y-0 lg:-right-32 lg:w-1/2">
            <div className="relative z-10 px-6  sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-7xl lg:h-full lg:pl-0 transform">
              <AnimatePresence>
                {mapReady ? (
                  <motion.div
                    key="exploreMap"
                    className="rounded-lg overflow-hidden lg:rounded-none lg:rounded-l-2xl shadow-2xl mb-6 lg:absolute z-10 w-full  relative"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.4, ease: 'easeIn' }}
                  >
                    <DynamicMap data={data?.queryListings?.edges} config={{ latitude, longitude }} />
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}
