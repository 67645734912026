import { Title } from '@/components'
import Image from 'next/image'
import homePerson from '../../../public/icons/3d/homeperson.png'

export const Contribute = () => (
  <div className="relative">
    <div className="max-w-7xl mx-auto py-16 px-6 lg:px-8">
      <div className="lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-0 pb-12 px-0 lg:pt-16 sm:pr-32 lg:py-16 ">
          <p className="text-brand-300 dark:text-melrose font-bold tracking-tight">Be a part of a community.</p>
          <Title as="h2" size="2xl" className="mt-2">
            A new take on the old way of renting
          </Title>
          <p className="mt-8 mb-4 text-lg dark:text-zinc-200 text-zinc-600 font-medium">
            Good Neighbour is a kinder rental platform for tenants and landlords. Our mission is to solve the rental
            industry’s most pressing problems: communication, showings and working with tenants that care.
          </p>
        </div>
        <div className="-mt-2 lg:-mt-0 w-full h-full  ">
          <div className="max-w-lg mx-auto w-full h-full rounded-full bg-emerald-100 shadow-2xl shadow-emerald-200/50 bg-opacity-70 border border-emerald-200/30">
            <div>
              <Image
                src={homePerson}
                placeholder="blur"
                className="w-full h-full object-center object-cover rounded-lg  "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
